import {
  Accordion,
  AccordionPanel,
  Anchor,
  Box,
  Button,
  Card, CardBody, CardHeader, CheckBox, FileInput, Footer, Form, FormField, Grid,
  grommet,
  Grommet,
  Header,
  Heading,
  Image,
  Nav,
  Notification,
  Page,
  PageContent,
  PageHeader,
  Paragraph, Spinner, Table, TableBody, TableCell, TableHeader, TableRow, Text, Tip
} from "grommet";
import { Moon, Sun } from "grommet-icons";
import { deepMerge } from "grommet/utils";
import React, { useState } from "react";
import $ from "jquery";

const theme = deepMerge(grommet, {
  global: {
    colors: {
      brand: "#969a7f",
      control: {
        dark: "brand",
        light: "brand",
      },
    },
    font: {
      family: "Open Sans",
      size: "16px",
      height: "1.6",
      color: "#46707f",
      weight: "400"
    },
  },
  tip: {
    drop: {
      background: "" // Sets non-transparent
    }
  }
});

const AppBar = (props) => (
  <Header
    background="brand"
    pad={{ left: "medium", right: "small", vertical: "medium" }}
    elevation="small"
    direction="row-responsive"
    {...props}
  />
);

const Result = ({ loading, result, download }) => {
  return (
    <Card>
      <CardHeader pad={{ "horizontal": "medium", "top": "medium" }}>
        <Heading level={2} margin="none">
          Result
        </Heading>
      </CardHeader>
      <CardBody pad="medium">
        {loading
          ? <Box align="center"><Spinner /></Box>
          : <Paragraph fill color="#46707f">{result.message}{result.status === 0 && " Your document is authentic and Quantum-safe."}</Paragraph>
        }

        {result.status === 0 && result.links.length > 0 &&
          <>
            <Paragraph fill color="#46707f">If you run your own Document Inoculation service, you would be hosting these proofs.</Paragraph>
            {result.links.map((link, idx) => (
              <Button
                key={idx}
                secondary
                margin={{ "horizontal": "medium", "bottom": "xsmall" }}
                label={"Download Proof " + (idx + 1)}
                href={"/proofs/" + link}
                download={download + "-" + (idx + 1) + ".pqspp"}
              />
            ))}
          </>
        }
      </CardBody>
    </Card>
  )
}

const panelHeader = (title) => (
  <Heading level="2">{title}</Heading>
)

const SectionHeader = ({ title }) => (
  <Heading fill level="3">{title}</Heading>
)


const App = () => {
  const [dark, setDark] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("");
  const [valid, setValid] = useState(false);
  const [download, setDownload] = useState("");
  const [open, setOpen] = useState(false);
  const [copiedName, setCopiedName] = useState(false);
  const [copiedUrl, setCopiedUrl] = useState(false);

  const signserverUrl = process.env.REACT_APP_SIGNSERVER_URL;
  const pdfFileId = "pdf_file";
  const proofFileId = "prf_file";

  const verify = (e) => {
    e.preventDefault();
    var data = new FormData($("#verifyform")[0]);
    setDownload($("#verifyform")[0].pdf_file.files[0].name);

    $.ajax({
      url: "/submit",
      type: "post",
      data: data,
      contentType: false,
      processData: false,
      beforeSend: function () {
        setLoading(true);
        setResult("");
      },
    })
      .always(function () {
        setLoading(false);
      })
      .fail(function (jqxhr, exception) {
        var json = {};
        json.message = jqxhr.status + " " + exception;
        setResult(json);
      })
      .then(function (data) {
        setResult(data);
      })
  }

  return (
    <Grommet theme={theme} full themeMode={dark ? "dark" : "light"}>
      <Page kind="narrow">
        <AppBar>
          <Text size="2.25em" font-weight="300" color="#fffffd">Document Inoculation against Quantum Attacks (Demo)</Text>
          <Nav direction="row">
            <Button
              primary
              color="white"
              alignSelf="center"
              label="inoQulate by pQCee"
              href="https://www.pqcee.com/inoqulate"
            />
            <Button
              a11yTitle={dark ? "Switch to Light Mode" : "Switch to Dark Mode"}
              icon={dark ? <Moon /> : <Sun />}
              onClick={() => setDark(!dark)}
              tip={{
                content: (
                  <Box
                    pad="small"
                    round="small"
                    background={dark ? "dark-1" : "light-3"}
                  >
                    {dark ? "Switch to Light Mode" : "Switch to Dark Mode"}
                  </Box>
                ),
                plain: true,
              }}
            />
          </Nav>
        </AppBar>
        <PageContent>
          <PageHeader id="verify-qs-authenticity" title="Verify Quantum-safe Authenticity" />
          <Form id="verifyform">
            <Grid columns="medium" gap="medium">
              <Card>
                <CardHeader pad={{ "horizontal": "medium", "top": "medium" }}>
                  <Heading level={2} margin="none">
                    Inoculated PDF Document
                  </Heading>
                </CardHeader>
                <CardBody pad="medium">
                  <Paragraph fill color="#46707f">
                    After inoculating your document using our service, upload your inoculated document
                    here to verify its inoculation status.
                  </Paragraph>
                  <Notification
                    status="warning"
                    title="Please refrain from uploading sensitive documents."
                    message="This service is a demo. Verifying your document's inoculation status will
                      upload a copy to our servers."
                    margin={{ "vertical": "small" }}
                  />
                  <FormField
                    name={pdfFileId}
                    htmlFor={pdfFileId}
                  >
                    <FileInput
                      name={pdfFileId}
                      id={pdfFileId}
                      accept=".pdf"
                      onChange={(e) => {
                        if (e.target.files.length > 0) {
                          setValid(true);
                        } else {
                          setValid(false);
                        }
                      }}
                    />
                  </FormField>
                </CardBody>
              </Card>
              <CheckBox
                label="Advanced"
                checked={open}
                onChange={(e) => setOpen(e.target.checked)}
              />
              <Card height={open ? "" : "0"} margin="none">
                <CardHeader pad={{ "horizontal": "medium", "top": "medium" }}>
                  <Heading level={2} margin="none">
                    [Optional] Quantum-safe Proof of Authenticity
                  </Heading>
                </CardHeader>
                <CardBody pad="medium">
                  <Paragraph fill color="#46707f">
                    Upload the proof here, or leave this blank to fetch the proof from our
                    servers. You should also leave this blank if your document has been
                    innoculated more than once.
                  </Paragraph>
                  <FormField
                    name={proofFileId}
                    htmlFor={proofFileId}
                  >
                    <FileInput
                      name={proofFileId}
                      id={proofFileId}
                      accept=".pqspp"
                    />
                  </FormField>
                </CardBody>
              </Card>
              <Button
                margin={{ "horizontal": "medium" }}
                size="large"
                primary
                label="Verify!"
                onClick={verify}
                type="submit"
                disabled={!valid}
              />
              <Result
                loading={loading}
                result={result}
                verify={verify}
                valid={valid}
                download={download}
              />
            </Grid>
          </Form>
          <Accordion multiple>
            <AccordionPanel header={panelHeader("What does Document Inoculation mean?")}>
              <Paragraph fill>
                A document is inoculated when it is tamper-proof, especially in
                the post-Quantum world.
              </Paragraph>
              <Paragraph fill>
                pQCee is testing such a Document Inoculation service. We
                distribute our service using existing Digital Signing Algorithms
                (FIPS-186) to ensure backward compatibility with your
                applications.  We inoculate your documents by adding a
                quantum-resistant layer inside our algorithm.
              </Paragraph>
              <Paragraph fill>
                With our service, your inoculated document is certified
                authentic by verifying the Digital Signature against a
                forward-computed Quantum-safe Proof of Authenticity.  A
                malicious actor cannot reverse-compute a valid Proof of
                Authenticity after creating a tampered document, even with
                access to Quantum computers.
              </Paragraph>
              <Paragraph fill>
                Hence, producing a valid Digital Signature with the
                corresponding Proof of Authenticity for verification provides
                you the confidence that your document is authentic.
              </Paragraph>
            </AccordionPanel>
            <AccordionPanel header={panelHeader("Why should I inoculate my document?")}>
              <Paragraph fill>
                A malicious actor with access to Quantum computers could tamper
                with your document, and still produce a valid Digital Signature.
                This is concerning because a Digital Signature is widely
                recognised and trusted as a certification of authenticity.
              </Paragraph>
              <Paragraph fill>
                Digital Signatures are widely recognised and trusted because
                they are the current cryptographic standard for proving
                authenticity. However, once Quantum computers are powerful
                enough, documents digitally signed using the current
                cryptographic standards would no longer be enough to proof
                authenticity of the document.
              </Paragraph>
              <Paragraph fill>
                It is estimated that within a decade, the first Quantum
                computers would be powerful enough to break the existing Digital
                Signing and Public Key Encryption cryptosystem. These Quantum
                computers can recover the Private Key from a Public Key within
                reasonable time.
              </Paragraph>
              <Paragraph fill>
                Anyone with possession of your authority's Private Key can
                pretend to be your trusted authority and certify tampered
                documents authentic, because they too can create valid Digital
                Signatures.  Our service protects against this spoofing by
                providing a Quantum-safe Proof of Authenticity for every
                inoculated document.
              </Paragraph>
            </AccordionPanel>
            <AccordionPanel header={panelHeader("How do I inoculate my document?")}>
              <Paragraph fill>
                We invite you to test the pQCee Document inoQulate service. In
                the following step-by-step guide, you will inoculate our <Anchor
                  href={process.env.REACT_APP_SAMPLE_PATH} download>sample PDF
                  file</Anchor>. Alternatively, you may inoculate a PDF file of
                your choice.
              </Paragraph>
              <Notification
                status="info"
                message="Certain PDF files (e.g. locked PDF files) are currently
                not supported in this demo as they may require a different
                technical workflow."
                margin={{ "vertical": "small", "horizontal": "none" }}
              />
              <ol>
                <SectionHeader title="Connecting to our Document Inoculation Server" />
                <li>
                  <Paragraph fill>Open the PDF file you wish to inoculate with
                    <Anchor href="https://get.adobe.com/reader/">Adobe Acrobat
                      Reader</Anchor>.</Paragraph>
                </li>
                <li>
                  <Paragraph fill>Click Edit &gt; Preferences &gt; Signatures
                    &gt; Document Timestamping &gt; More...</Paragraph>
                </li>
                <Image fill src="/images/addserver.png" alt="Add Timestamp Server" />
                <li>
                  <Paragraph fill>Check that the following entry exists.</Paragraph>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableCell><Text>Field</Text></TableCell>
                        <TableCell><Text>Value</Text></TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      <TableRow>
                        <TableCell><Text>Name</Text></TableCell>
                        <TableCell style={{ "wordBreak": "break-word" }}>
                          <Text><b>pQCee Document inoQulate Server</b></Text>
                        </TableCell>
                        <TableCell>
                          <Button
                            secondary
                            size="small"
                            label={copiedName ? "Copied!" : "Copy"}
                            onClick={() => {
                              navigator.clipboard.writeText("pQCee Document Inoculation Server")
                              setCopiedName(true)
                            }}
                            type="submit"
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell><Text>(Server) URL</Text></TableCell>
                        <TableCell style={{ "wordBreak": "break-all" }}>
                          <Text><Anchor href={signserverUrl}>{signserverUrl}</Anchor></Text>
                        </TableCell>
                        <TableCell>
                          <Button
                            secondary
                            size="small"
                            label={copiedUrl ? "Copied!" : "Copy"}
                            onClick={() => {
                              navigator.clipboard.writeText(signserverUrl)
                              setCopiedUrl(true)
                            }}
                            type="submit"
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Paragraph fill>
                    If the entry does not exist, click New, and enter the
                    information as above.  Leave 'This server requires me to log
                    on' unchecked and click OK.
                  </Paragraph>
                </li>
                <li>
                  <Paragraph fill>Make sure that the above entry is the default
                    by checking that it is marked by a star. If it is not, select
                    the entry, click Set Default and click OK to
                    confirm.</Paragraph>
                </li>
                <Image fill src="/images/setdefault.png" alt="Set as the default Time Stamp Server" />
                <li><Paragraph fill>Close the dialog boxes.</Paragraph></li>

                <SectionHeader title="Inoculating your document" />
                <li><Paragraph fill>Click Tools &gt; Certificates &gt; Time Stamp.</Paragraph></li>
                <Image fill src="/images/certificates.png" alt="Certificate Toolbar" />
                <li><Paragraph fill>You are prompted to choose a location to save your inoculated file.
                  For ease of the demo, we recommend saving it as <Tip
                    content="Click to copy name"
                    onClick={() => navigator.clipboard.writeText("inoQulated")}>
                    <Anchor>inoQulated.pdf</Anchor>
                  </Tip> in your <code>Downloads</code> folder.
                </Paragraph></li>
                <li>
                  <Paragraph fill>You may receive the following Security Alerts
                    and Warnings. Click Allow/Yes to continue.</Paragraph>
                </li>
                <Box align="center">
                  <Image
                    width="80%"
                    src="/images/connectionwarning.png"
                    alt="Connecting to Timestamp Server Warning"
                    margin={{ "bottom": "small" }}
                  />
                  <Image
                    width="80%"
                    src="/images/untrustedcert.png"
                    alt="Untrusted Certificate Alert"
                  />
                </Box>
                <Paragraph>Your document has now been inoculated!</Paragraph>
                <Paragraph fill>
                  If this is the first time inoculating your document using our
                  service, continue to <Anchor href="#trusting-cert">Trusting
                    our certificate</Anchor>.  Otherwise, jump to <Anchor
                      href="#verify-status">Verifying the inoculation
                    status</Anchor>.
                </Paragraph>

                <SectionHeader title="Trusting our certificate" />
                <li id="trusting-cert">
                  <Paragraph fill>Close and open the inoculated file
                    <code>inoQulated.pdf</code> in your <code>Downloads</code>
                    folder.</Paragraph>
                </li>
                <li>
                  <Paragraph fill>Open the Signature Panel, expand the pQCee
                    TimeStamp Service signature on the left panel, expand Signature
                    Details, and click Certificate Details.</Paragraph>
                </li>
                <li>
                  <Paragraph fill>Click the Trust tab.</Paragraph>
                </li>
                <li>
                  <Paragraph fill>Click Add to Trusted Certificates and click
                    OK.</Paragraph>
                </li>
                <li>
                  <Paragraph fill>Leave the settings as they are and click OK
                    again.</Paragraph>
                </li>
                <li>
                  <Paragraph fill>Click OK again to close the Certificate
                    Viewer.</Paragraph>
                </li>
                <Image fill src="/images/trustcert.png" alt="Trust certificate as root" />

                <SectionHeader title="Verifying the inoculation status" />
                <li id="verify-status">
                  <Paragraph fill>Close and open your inoculated document
                    <code>inoQulated.pdf</code> in your <code>Downloads</code>
                    folder again, or click Tools &gt; Certificates &gt; Validate
                    All Signatures.  You should see a green tick and that the
                    document is signed and all signatures are valid.
                  </Paragraph>
                </li>
                <Image fill src="/images/verified.png" alt="Verified all signatures" />
                <li>
                  <Paragraph fill>Finally, upload your inoculated document
                    <code>inoQulated.pdf</code> in your <code>Downloads</code>
                    folder to our <Anchor
                      href="#verify-qs-authenticity">verifier</Anchor> and click
                    Verify.</Paragraph>
                </li>
              </ol>
            </AccordionPanel>
          </Accordion>
        </PageContent>
      </Page>
      <Footer pad="medium">
        <Box />
        <Text>&copy; 2023 pQCee Pte Ltd</Text>
        <Text>v{process.env.REACT_APP_VERSION}</Text>
      </Footer>
    </Grommet >
  );
};

export default App;